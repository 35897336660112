import bg1 from "../images/bg1.jpg";
import loveAlbum from "../images/love-album.jpg";
import sixtyMiniatures from "../images/sixty-miniatures.jpg";
import weekInTheLife from "../images/week-in-the-life.jpg";
import andThenEverythingWasSung from "../images/And-then-everything-was-sung.png";
import unreasonable from "../images/Unreasonable.png";

//Fargions
// import bminor from "../images/B-minor.png";
// import aurevoir from "../images/Aurevoir.jpg";
// import twtr from "../images/Together-with-the-rest.jpg";


const images = {
    //Background img
  bg1,
  //Projects
  loveAlbum,
  sixtyMiniatures,
  weekInTheLife,
  andThenEverythingWasSung,
  unreasonable
}


export default images;
